@font-face {
font-family: '__ringside_condensed_460364';
src: url(/_next/static/media/6d2984b0a2654a76-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__ringside_condensed_460364';
src: url(/_next/static/media/f7df5bcebf626e6d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__ringside_condensed_Fallback_460364';src: local("Arial");ascent-override: 117.78%;descent-override: 29.33%;line-gap-override: 0.00%;size-adjust: 81.92%
}.__className_460364 {font-family: '__ringside_condensed_460364', '__ringside_condensed_Fallback_460364'
}.__variable_460364 {--font-ringside-condensed: '__ringside_condensed_460364', '__ringside_condensed_Fallback_460364'
}

@font-face {
font-family: '__ringside_compressed_82a49e';
src: url(/_next/static/media/1876563d87ff04c8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__ringside_compressed_82a49e';
src: url(/_next/static/media/3600f9e4b045efc0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__ringside_compressed_Fallback_82a49e';src: local("Arial");ascent-override: 131.10%;descent-override: 32.60%;line-gap-override: 0.00%;size-adjust: 73.61%
}.__className_82a49e {font-family: '__ringside_compressed_82a49e', '__ringside_compressed_Fallback_82a49e'
}.__variable_82a49e {--font-ringside-compressed: '__ringside_compressed_82a49e', '__ringside_compressed_Fallback_82a49e'
}

